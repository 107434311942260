// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.time-picker-container {
  margin-top: 15px;
  background: #24384a;
  max-height: 324px;
  width: 332px;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  right: 608px;
  z-index: 50;
}
@media (max-width: 1349px) {
  .time-picker-container {
    right: 400px;
  }
}
@media (max-width: 1079px) {
  .time-picker-container {
    right: 240px;
  }
}
@media (max-width: 859px) {
  .time-picker-container {
    left: 0px;
  }
}
@media (max-width: 599px) {
  .time-picker-container {
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}

p {
  display: inline;
  margin-left: 20px;
}

.radio-btn-container {
  display: flex;
}

.field {
  display: inline-flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
}

label {
  letter-spacing: 0.05em;
  font-size: 14px;
  margin-bottom: -22px;
  margin-left: 15px;
  color: #7089a4;
  z-index: 100;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
