// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.subheading {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin: 22px 0 4px 0;
}
.button-container .cancel-btn {
  border: 2px solid #99bdd1;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: transparent;
}
.button-container .cancel-btn:hover {
  border: 2px solid #74909f;
}
.button-container .cancel-btn:focus {
  border: 2px solid #52aaff;
}
.button-container .submit-btn {
  width: 160px;
  margin-left: 16px;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #3e84c9;
  color: #dce8f3;
}
.button-container .submit-btn:disabled {
  background-color: #3f5061;
  color: #607387;
}
.button-container .submit-btn:hover:not(:disabled):not(:active) {
  background-color: #2e5a8d;
}
.button-container .submit-btn:active {
  background-color: #438db6;
}

ul {
  margin: 0 0 6px 0;
  max-height: 100px;
  overflow-y: auto;
}
ul::-webkit-scrollbar {
  width: 10px;
}
ul::-webkit-scrollbar-track {
  background: #24384a;
}
ul::-webkit-scrollbar-thumb {
  background: #15202b;
  border-radius: 10px;
}
ul li {
  margin-bottom: 10px;
}

.alert-options-container {
  width: 100%;
}
.alert-options-container .flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-options-container .flexbox ::ng-deep.dropdown.small {
  width: 285px;
}

.announcement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.announcement-header .add-announcement-btn {
  background-color: transparent;
  color: #52aaff;
  font-size: 14px;
}
.announcement-header .add-announcement-btn.disabled {
  cursor: not-allowed;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
