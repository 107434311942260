// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.filter-container {
  position: absolute;
  right: 25px;
  z-index: 5;
  width: 900px;
}
@media (max-width: 1349px) {
  .filter-container {
    width: 720px;
  }
}
@media (max-width: 1079px) {
  .filter-container {
    width: 470px;
  }
}
@media (max-width: 859px) {
  .filter-container {
    left: 25px;
  }
}
@media (max-width: 599px) {
  .filter-container {
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
  }
}

.filter-flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 900px;
}
@media (max-width: 1349px) {
  .filter-flex-container {
    width: 720px;
    flex-flow: row wrap;
  }
}
@media (max-width: 1079px) {
  .filter-flex-container {
    width: 470px;
  }
}
@media (max-width: 859px) {
  .filter-flex-container {
    justify-content: flex-start;
  }
}
@media (max-width: 599px) {
  .filter-flex-container {
    width: 250px;
  }
}

.filter {
  background: #3b5873;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  min-width: 245px;
  height: 45px;
  color: #9dbcda;
  text-align: center;
  text-indent: -20px;
  margin-bottom: 6px;
}

svg {
  margin-left: -25px;
  pointer-events: none;
}

.filter-placeholder {
  font-style: italic;
  color: #6e849f;
}

.filter-component-container {
  margin-right: 20px;
}

.filter-box {
  display: inline;
  margin-bottom: 6px;
}

.filter-flex-pair {
  display: flex;
  flex-flow: row nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
