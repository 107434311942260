// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.announcement-modal {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 18px;
  background: #24384a;
  height: 260px;
  width: 400px;
  border-radius: 5px;
  padding: 20px;
  z-index: 50;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
}

.title {
  margin-left: 12px;
  color: #52aaff;
}
.title:hover {
  cursor: pointer;
}

.chevron {
  margin-left: 20px;
  width: 20px;
}
.chevron:hover {
  cursor: pointer;
}
.chevron img {
  height: 10px;
  width: 10px;
}

.train-numbers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 24px;
}
.train-numbers .train-number {
  border-radius: 5px;
  font-size: 11px;
  background: #15202b;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 8px;
  margin: 2px;
  color: #6aa5da;
  word-break: keep-all;
}

.empty-text {
  margin-left: 24px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-container .header {
  font-weight: bold;
  margin-left: 24px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}
.checkbox-container .checkbox {
  margin-left: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
