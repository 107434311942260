// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
span {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.radio-button-container {
  display: inline-block;
  position: absolute;
}

.text-container {
  display: inline-block;
  margin-left: 30px;
  width: 90%;
  height: 35px;
}

.announcement-text-option-container {
  margin: 5px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
