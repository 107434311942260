import Aura from "@primeng/themes/aura";
import {definePreset} from "@primeng/themes";

export const KvitterPreset = definePreset(Aura, {
    semantic: {
        primary: {
            0: "#ffffff",
            50: "#f7fbff",
            100: "#daedfd",
            200: "#bcdffb",
            300: "#9fd1f9",
            400: "#81c3f8",
            500: "#64b5f6",
            600: "#559ad1",
            700: "#467fac",
            800: "#376487",
            900: "#284862",
            950: "#022c22"
        },
        colorScheme: {
            text: {
                color: "{primary.0}",
                hoverColor: "{primary.0}",
                mutedColor: "{primary.400}",
                hoverMutedColor: "{primary.300}"
            },
            content: {
                background: "{primary.900}",
                hoverBackground: "{primary.800}",
                borderColor: "{primary.700}",
                color: "{text.color}",
                hoverColor: "{text.hover.color}"
            },
        },
    },
    components: {
        inputtext: {
            root: {
                background: "#3b5873",
                color: "#9dbcda",
                borderColor: "transparent",
                hoverBorderColor: "{primary.500}",
            },
        },
    }
});

