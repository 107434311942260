import { DatePipe, registerLocaleData } from "@angular/common";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeSv from "@angular/common/locales/sv";
import {
  LOCALE_ID,
  NgModule,
  APP_INITIALIZER,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalModule,
  MsalService,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import {
  MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory,
} from "../assets/config/msal-config";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AnnouncementFilterComponent } from "./home/announcement-filter/announcement-filter.component";
import { LineMapZoomComponent } from "./home/announcement-filter/line-map-zoom/line-map-zoom.component";
import { EventModule } from "./home/event-container/event.module";
import { FiltersModule } from "./home/filters/filters.module";
import { HomeComponent } from "./home/home.component";
import { LineMapModule } from "./home/line-map/line-map.module";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { NotificationItemComponent } from "./nav-menu/user-menu/notification-item/notification-item.component";
import { UserMenuComponent } from "./nav-menu/user-menu/user-menu.component";
import { NotAuthorizedComponent } from "./not-authorized/not-authorized.component";
import { RedirectPageComponent } from "./redirect-page/redirect-page.component";
import { SharedModule } from "./shared/shared.module";
import { RetryInterceptor } from "./shared/interceptors/retry-interceptor";
import { DateInterceptor } from "./shared/interceptors/date-interceptor";
import { CategoryFilterComponent } from "./home/announcement-filter/category-filter/category-filter.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";
import { AuthInterceptor } from "./shared/interceptors/AuthInterceptor";
import { providePrimeNG } from "primeng/config";
import { KvitterPreset } from "./primeng.theme";
registerLocaleData(localeSv, "sv");

// AoT (Ahead-of-Time) compiler requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

const scopeUserRead: string = "user.read";
export const protectedResourceMap: Map<string, Array<string>> = new Map<string, Array<string>>([
  [environment.actionApi.path, environment.config.actionScope],
  [environment.reasonApi.path, environment.config.reasonScope],
  [environment.stationsApi.path, environment.config.stationsScope],
  [environment.linenumberApi.path, environment.config.linenumberScope],
  [environment.eventLogApi.path, environment.config.eventlogScope],
  [environment.notificationApi.path, environment.config.notificationScope],
  [environment.trainApi.path, environment.config.trainScope],
  ["https://graph.microsoft.com/v1.0/me", [scopeUserRead]],
  ["https://graph.microsoft.com/v1.0", [scopeUserRead]],
]);

export function MSALInitializerFactory(msalService: MsalService) {
  return async () => {
    await msalService.initialize();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    UserMenuComponent,
    NotificationItemComponent,
    NotAuthorizedComponent,
    RedirectPageComponent,
    AnnouncementFilterComponent,
    LineMapZoomComponent,
    CategoryFilterComponent
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    FiltersModule,
    LineMapModule,
    EventModule,
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    MsalModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
   // MultiSelectModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "sv-SE" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    providePrimeNG({
      theme: {
        preset: KvitterPreset,
      }
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: MSALInitializerFactory,
      deps: [MsalService],
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
