// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(20, 20, 20, 0.5);
}

.popup {
  border-radius: 5px;
  background: #24384a;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 20px;
  resize: both;
  overflow: auto;
  max-width: 90vw;
  max-height: 90vh;
  min-width: max(20vw, 400px);
  min-height: max(20vh, 200px);
}

.header {
  margin-bottom: 20px;
  justify-self: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
}

.display-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  flex-shrink: 1;
  flex-grow: 1;
}
.display-text > div {
  margin-bottom: 20px;
}
.display-text :last-child {
  margin-bottom: 0;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  justify-self: flex-end;
  flex-shrink: 0;
  flex-grow: 0;
}

button.big {
  padding: 10px 20px;
  height: 45px;
  margin: 5px;
  flex-grow: 1;
  max-width: 200px;
}

.key-value-exceptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.key-value-exceptions .key-values {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.key-value-exceptions .key-values .values {
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
}
.key-value-exceptions .key-values .values > div {
  margin: 0 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
