// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.announcement-filter-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  left: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  color: #9dbcda;
}

.announcementfilter-flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 200px;
  height: 32px;
}

.filter-component-container {
  margin-right: 20px;
  color: #9dbcda;
  height: 32px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
