// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.train-number-picker-container {
  margin-top: 15px;
  background: #24384a;
  width: 332px;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  right: 400px;
  z-index: 50;
}
@media (max-width: 1349px) {
  .train-number-picker-container {
    right: 200px;
  }
}
@media (max-width: 1079px) {
  .train-number-picker-container {
    right: 40px;
  }
}
@media (max-width: 859px) {
  .train-number-picker-container {
    right: -20px;
  }
}
@media (max-width: 599px) {
  .train-number-picker-container {
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
  }
}

p {
  display: inline;
  margin-left: 20px;
}

.train-filtering-container {
  margin-top: 1em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
