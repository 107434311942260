// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.filter {
  margin-right: 10px;
  min-width: 200px;
}
@media (max-width: 1079px) {
  .filter {
    min-width: 190px;
  }
}
@media (max-width: 599px) {
  .filter {
    width: 238px;
  }
}

.filter[disabled] {
  background-color: #3f5061;
  color: #607387;
}

.display-value {
  text-overflow: ellipsis;
  width: 165px;
  overflow: hidden;
  display: block;
  text-indent: 5px;
  white-space: nowrap;
}

svg {
  margin-left: -35px;
}

.date {
  width: 220px;
  padding-right: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
