// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
g,
circle {
  pointer-events: auto;
}

.map-node {
  fill: #384755;
  stroke: #6e849f;
  stroke-width: 3;
}

.map-dot-border.active {
  stroke: #dce8f3;
  stroke-width: 3;
}

circle.map-dot.active {
  fill: #2e98ff;
  stroke-width: 3;
}

.even {
  fill: #2e98ff;
}

.odd {
  fill: #3c5f85;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
