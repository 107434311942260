// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.clear-text-btn-primary {
  position: initial;
}

.clear-text-btn-primary.active,
.clear-text-btn-primary:hover {
  color: #52aaff;
}

svg:hover > path {
  fill: #52aaff;
  cursor: pointer;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
