// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: inline-block;
  width: 22px;
}

.info-btn {
  border: 2px solid #9dbcda;
  background: transparent;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  box-sizing: border-box; /* Set box-sizing to put border inside div*/
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

svg {
  position: relative;
  left: 8px;
  bottom: 3px;
}

.info-btn.disabled > svg > path {
  fill: #74909f;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
