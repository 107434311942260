// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.user-menu-btn {
  margin-left: 30px;
  height: 50px;
}
@media (max-width: 767px) {
  .user-menu-btn {
    height: 8px;
  }
}

.user-menu-btn:hover > path {
  fill: #52aaff;
}

.user-menu-btn.active > path {
  fill: #52aaff;
}

.notification-list-container {
  margin-top: 10px;
  max-height: 260px;
  overflow-y: auto;
}

.notification-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f13965;
  display: inline-block;
  margin-bottom: 3px;
  margin-left: -3px;
  visibility: hidden;
}

.no-notifications {
  font-size: 14px;
}

.notification-icon.active {
  visibility: inherit;
}

.user-menu {
  width: 332px;
  background: #24384a;
  border-radius: 5px;
  position: absolute;
  top: 40px;
  right: 45px;
  visibility: hidden;
  padding: 16px 22px;
  z-index: 17;
}

.user-menu.active {
  visibility: inherit;
}

.user-menu-header {
  display: inline-block;
}

.clear-text-btn-secondary.right {
  right: 20px;
}

.clear-text-btn-secondary.center {
  margin: 10px auto;
  display: block;
  position: relative;
}

.clear-text-btn-primary {
  width: calc(100% - 44px);
  text-align: left;
  padding: 0;
}

.clear-text-btn-primary:hover {
  color: #52aaff;
}

.logout-chevron {
  position: absolute;
  right: 0;
  top: 25%;
}

.clear-text-btn-primary:hover > .logout-chevron > path {
  fill: #52aaff;
}

.version-text {
  color: #73889c;
  font-size: 12px;
  display: block;
}

hr {
  margin-bottom: 25px;
}

.user-info {
  font-size: 12px;
}

.logout-container {
  margin-bottom: 30px;
  display: flex;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
