// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.action-item-btn {
  background: #3b5873;
  padding: 10px;
  border-radius: 5px;
  margin: auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  align-content: stretch;
}
.action-item-btn span {
  line-height: 120%;
  word-break: break-word;
}

.action-btn-chevron {
  flex-shrink: 0;
  margin: 5px 5px 5px 10px;
}

.title {
  text-align: left;
  flex-grow: 1;
}

.status-icon {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: 5px 10px 5px 5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  align-content: center;
}
.status-icon > .status-icon-svg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.status-icon.notified {
  background: #52aaff;
}
.status-icon.new {
  border: 3px solid #99bdd1;
}
.status-icon.unsent {
  background: #e99549;
}
.status-icon.accepted {
  background: #33c3aa;
}
.status-icon.accepted > .status-icon-svg {
  background-image: url('Active.e598474d6c34f7e6.svg');
}
.status-icon.rejected, .status-icon.error {
  background: #f13965;
}
.status-icon.rejected > .status-icon-svg, .status-icon.error > .status-icon-svg {
  background-image: url('Rejected.59f0a592802903b5.svg');
}
.status-icon.sent {
  background: #e99549;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
