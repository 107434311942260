export interface IAnnouncementProperty {
  id: number;
  order: number;
  key: string;
  value: string;
}

export class AnnouncementProperty implements IAnnouncementProperty {
  public id: number;
  public order: number;
  public key: string;
  public value: string;

  constructor(property: IAnnouncementProperty) {
    this.id = property.id;
    this.order = property.order;
    this.key = property.key;
    this.value = property.value;
  }
}
