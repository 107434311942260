// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.announcement-dropdown-container,
.announcement-input-container {
  width: 49%;
  display: inline-block;
}

.announcement-input-container {
  margin-left: 2%;
}

.clear-text-btn-primary {
  display: contents;
}

.announcement-warning-container {
  border: 2px solid #cf6363;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  width: 100%;
  padding: 10px;
}

.replacement-warning-text {
  color: #eec9c9;
}

.status-icon {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: 5px 10px 5px 5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  align-content: center;
}
.status-icon > .status-icon-svg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.status-icon.replaced > .status-icon-svg {
  background-image: url('Attention.9bb70de13d884f60.svg');
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
