// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.increment-btn {
  background: #3b5873;
  border-radius: 5px;
  height: 32px;
  width: 64px;
}
@media (max-width: 599px) {
  .increment-btn {
    display: none;
  }
}

.divider-container {
  display: inline-block;
}

.clear-text-btn-primary {
  display: initial;
  height: 32px;
  width: 30px;
  position: relative;
}

svg {
  pointer-events: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
