// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
header {
  z-index: 1000;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
}
.navbar.bg-dua {
  background: #15202b;
  height: 50px;
  border-bottom: 1px solid #24384a;
}

.navbar-collapse {
  background-color: #15202b;
  z-index: 15;
  border-bottom: 1px solid #24384a;
}

.nav-link {
  color: #9dbcda;
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.nav-link:hover {
  color: #52aaff;
}

.nav-item.link-active > .nav-link {
  color: #52aaff;
}

.nav-item > .active-link-border {
  border-radius: 2px;
  width: 130px;
  height: 3px;
  background: #52aaff;
  visibility: hidden;
}

.nav-item.link-active > .active-link-border {
  visibility: inherit;
}
@media (max-width: 859px) {
  .nav-item.link-active > .active-link-border {
    margin: auto;
  }
}

.environment-label {
  font-size: 12px;
}

.display-sm-size {
  display: none;
}
@media (max-width: 767px) {
  .display-sm-size {
    display: block;
  }
}

.display-md-size {
  display: block;
}
@media (max-width: 767px) {
  .display-md-size {
    display: none;
  }
}

.button-flex-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: center;
  margin-bottom: 16px;
}
.button-flex-container .navbar-toggler {
  font-size: 12px;
  height: 35px;
  margin-left: 4px;
  padding: 8px 8px 10px 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
