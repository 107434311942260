// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
select {
  background: #24384a;
  border: none;
  font-size: 12px;
  width: 160px;
  height: 20px;
  color: #9dbcda;
  text-indent: 20px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select-container:hover > select {
  color: #52aaff;
}

select:focus {
  outline: none;
}

.select-container > svg {
  pointer-events: none;
}

.select-container:hover > svg > path {
  fill: #52aaff;
}

svg {
  position: absolute;
  left: 145px;
  top: 94px;
}

select > option {
  padding: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
