import { Component, forwardRef, Input, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { AnnouncementProperty } from "../../../../models/announcement/announcement-property";

@Component({
  selector: "dua-announcement-property",
  templateUrl: "./announcement-property.component.html",
  styleUrls: ["./announcement-property.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnnouncementPropertyComponent),
      multi: true,
    },
  ],
})
export class AnnouncementPropertyComponent
  implements OnInit, ControlValueAccessor {
  @Input() property: AnnouncementProperty;
  @Input() parentForm: FormGroup;
  @Input() formControlName: string;

  constructor() {}

  ngOnInit(): void {}

  propertyValueChange(event) {
    this.property.value = event;
    this.propagateChange(this.property.value);
  }

  writeValue(value: any) {}

  propagateChange = (_: any) => {};

  registerOnChange(fn) {}

  registerOnTouched() {}

  saveInput(event: any): void {
    this.property.value = event;
    this.propagateChange(this.property.value);
  }
}
