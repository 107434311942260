// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.affected-trains-container {
  margin: 10px 0 6px 0;
}
.affected-trains-container h3 {
  font-size: 14px;
  font-weight: 400;
}
.affected-trains-container p {
  margin: 0;
  padding: 0;
}
.affected-trains-container .affected-trains-wrapper {
  width: 100%;
  max-height: 80px;
  overflow-y: auto;
  margin: 0 -3px;
  display: flex;
  flex-flow: row wrap;
}
.affected-trains-container .affected-trains-wrapper.expand {
  max-height: 180px;
}
.affected-trains-container .affected-trains-wrapper .train {
  margin: 3px;
  padding: 0 8px;
  font-size: 11px;
  background: #607387;
  color: #9dbcda;
  border-radius: 5px;
}
.affected-trains-container .affected-trains-wrapper .train.activityTrain {
  background: #15202b;
  color: #6aa5da;
}
.affected-trains-container .affected-trains-wrapper .train.toBeDeletedTrain {
  background: #f13965;
  color: #dce8f3;
}

.clear-text-btn-secondary.center {
  margin: 2px auto 4px;
  display: block;
  position: relative;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
