// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.notification-icon {
  position: absolute;
  top: 38%;
  right: 12%;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #52aaff;
  visibility: hidden;
}

.notification-icon.active {
  visibility: inherit;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
