// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.filter {
  display: flex;
  align-items: center;
  min-width: 140px;
  padding: 1px 10px;
  text-indent: 0px;
}
@media (max-width: 1079px) {
  .filter {
    min-width: 172px;
  }
}
@media (max-width: 599px) {
  .filter {
    min-width: 180px;
  }
}
.filter.disabledFilter {
  background-color: #3f5061;
  color: #607387;
}

.checkbox-container {
  margin: 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
