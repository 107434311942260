// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
input:focus ~ .floating-label,
input:not(:placeholder-shown) ~ .floating-label {
  top: 8px;
  bottom: 10px;
  left: 20px;
  font-size: 13px;
  opacity: 1;
}

.input-text {
  background: #3b5873;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  width: 100%;
  height: 45px;
  color: #9dbcda;
  text-indent: 20px;
  font-family: Roboto;
  font-weight: 500;
  box-sizing: border-box;
  padding-top: 15px;
}
.input-text:disabled {
  background: #3f5061;
  color: #607387;
  border: none;
}

.input-text:focus {
  outline: none;
  border: 2px solid #52aaff;
  text-indent: 18px;
}

.input-text:hover:not(:disabled) {
  border: 2px solid #62809f;
  text-indent: 18px;
}

.floating-label {
  margin-top: -48px;
  pointer-events: none;
  padding-left: 10px;
  right: 20px;
  padding-top: 5px;
  font-size: 14px;
  line-height: 16px;
  transition: 0.2s ease all;
  display: flex;
  color: #9dbcda;
  font-family: Roboto;
  font-weight: 500;
}

.input-container {
  display: grid;
}

.input-container.filled > .floating-label {
  color: #7089a4;
}

.input-container.error > .input-text {
  border: 2px solid #c85570;
  text-indent: 18px;
  color: #d297a4;
  background: #84273c;
}

.input-container.error > .floating-label {
  color: #bb6a7c;
}

.adjusted {
  position: relative;
  top: 7px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
