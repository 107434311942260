import { Component, Input, OnInit } from "@angular/core";
import { Announcement } from "src/app/models/announcement/announcement";
import { AnnouncementService } from "src/app/services/announcement.service";
import { ListItemService } from "src/app/services/list-item.service";
import { AnnouncementState } from "../../../../../models/announcement/announcement-enums";

@Component({
  selector: "dua-announcement-list-item",
  templateUrl: "./announcement-list-item.component.html",
  styleUrls: ["./announcement-list-item.component.scss"],
})
export class AnnouncementListItemComponent implements OnInit {
  @Input() announcement: Announcement;
  public tooltipText: string;

  constructor(
    private listItemService: ListItemService,
    private announcementService: AnnouncementService
    ) {}
  
    ngOnInit() {
    this.setAnnouncementReplacementText();
  }

  public getClassNameFromState(): string {
    if (this.announcement.toBeDeleted) {
      this.announcement.state = AnnouncementState.Removed;
    }

    if (this.announcement.percentageReplacedByAnother > 0) {
      return "replaced";
    }

    return AnnouncementState[this.announcement.state]?.toLowerCase();
  }

  public getStateText(): string {
    switch (this.announcement.state) {
      case AnnouncementState.Changed:
        return "Ändrad.";
      case AnnouncementState.New:
        return "Ny.";
      case AnnouncementState.Removed:
        return "Struken.";
      default:
        return "";
    }
  }

  getIconFromStatus(): string {
    if (this.announcement.state === AnnouncementState.Unchanged &&
      this.announcement.percentageReplacedByAnother > 0) {
        return "attention";
    }
    if (this.announcement.state === AnnouncementState.Unchanged || 
        (this.announcement.state === AnnouncementState.Removed && this.announcement.status !== "Accepted" && this.announcement.toBeDeleted)) {
      return this.listItemService.getIconFromStatus(
        this.announcement?.notified,
        this.announcement?.status
      );
    }
    return this.listItemService.getIconFromState(this.announcement?.notified);
  }

  setAnnouncementReplacementText() {
    this.tooltipText = this.announcementService.getAnnouncementReplacementText(this.announcement);
  }
}
