// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.line-number-picker-container {
  margin-top: 15px;
  background: #24384a;
  min-height: 196px;
  width: 332px;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  right: 190px;
  z-index: 50;
}
@media (max-width: 1349px) {
  .line-number-picker-container {
    right: 40px;
  }
}
@media (max-width: 1079px) {
  .line-number-picker-container {
    right: 240px;
  }
}
@media (max-width: 859px) {
  .line-number-picker-container {
    left: 0px;
  }
}
@media (max-width: 599px) {
  .line-number-picker-container {
    top: 170px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.checkbox-container {
  display: inline-block;
  width: 95px;
  margin: 5px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
