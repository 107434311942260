// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
p {
  margin: 0 0 8px;
}

.text-area {
  background: #3b5873;
  border: 2px solid transparent;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  height: 52px;
  color: #9dbcda;
  font-family: Roboto;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.text-area.is-focused {
  border: 2px solid #52aaff;
}
.text-area.is-hovered {
  border: 2px solid #62809f;
}
.text-area textarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3b5873;
  border: none;
  outline: 0;
  width: 100%;
  height: 24px;
  overflow-y: auto;
  border-radius: 5px;
  margin: 0;
  padding: 0 20px;
  margin-top: -4px;
  resize: none;
  color: #9dbcda;
  cursor: auto;
}
.text-area textarea::-webkit-scrollbar {
  width: 10px;
}
.text-area textarea::-webkit-scrollbar-track {
  background: #3b5873;
}
.text-area textarea::-webkit-scrollbar-thumb {
  background: #15202b;
  border-radius: 10px;
}
.text-area label {
  opacity: 0.5;
  padding: 0 0 0 20px;
  margin: 0;
  font-size: 13px;
}

.disabled,
.disabled > textarea:disabled {
  background: #3f5061;
  color: #607387;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
