// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.event-list-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.event-list-header .header-left {
  display: flex;
  flex-wrap: nowrap;
}
.event-list-header h1 {
  margin: 0;
  padding: 0;
}
.event-list-header button {
  background-color: transparent;
  color: #52aaff;
  font-size: 12px;
}

.event-list-header.back-button-visible h1 {
  margin: 0 0 0 12px;
  padding: 0;
}

.clear-text-btn-primary {
  position: relative;
  margin-left: -6px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
