// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.line-map-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 599px) {
  .line-map-container {
    display: none;
  }
}
.line-map-container svg {
  height: 100%;
  width: 100%;
}

dua-map-node {
  position: absolute;
  pointer-events: none;
}

.svg-highlight {
  position: absolute;
  left: 0;
  pointer-events: none;
}

svg path.map-line {
  stroke: #24384a;
  stroke-width: 5;
}

svg path.map-line.small {
  stroke-width: 3;
}

svg path.map-line.extra-small {
  stroke-width: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
