// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.event-container {
  width: 360px;
  min-height: 170px;
  background: #24384a;
  border-radius: 5px;
  padding: 20px;
  margin-left: 15px;
  margin-top: 30px;
  display: inline-block;
  top: 45px;
  position: absolute;
  max-height: 80vh;
  overflow-y: auto;
}
@media (max-width: 859px) {
  .event-container {
    top: 180px;
    max-height: 50vh;
  }
}
@media (max-width: 599px) {
  .event-container {
    margin-left: 0;
    left: 50%;
    transform: translateX(-50%);
    top: 280px;
    max-height: 50vh;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
