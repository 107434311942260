import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DatePickerModule } from "primeng/datepicker";
import { TooltipModule } from "primeng/tooltip";
import { SharedModule } from "../../shared/shared.module";
import { DirectionFilterComponent } from "./direction-filter/direction-filter.component";
import { FilterButtonComponent } from "./filter-button/filter-button.component";
import { FilterHeaderComponent } from "./filter-header/filter-header.component";
import { FiltersComponent } from "./filters.component";
import { LineNumberFilterComponent } from "./line-number-filter/line-number-filter.component";
import { LineNumberPickerComponent } from "./line-number-filter/line-number-picker/line-number-picker.component";
import { TimeFilterComponent } from "./time-filter/time-filter.component";
import { TimePickerComponent } from "./time-filter/time-picker/time-picker.component";
import { TrainNumberFilterComponent } from "./train-number-filter/train-number-filter.component";
import { TrainNumberPickerComponent } from "./train-number-filter/train-number-picker/train-number-picker.component";
import { TrainSeriesButtonComponent } from "./train-number-filter/train-number-picker/train-series-button/train-series-button.component";
import { ResetFilterComponent } from "./reset-filter/reset-filter.component";

@NgModule({
  declarations: [
    DirectionFilterComponent,
    FilterButtonComponent,
    FilterHeaderComponent,
    LineNumberFilterComponent,
    TimeFilterComponent,
    TrainNumberFilterComponent,
    LineNumberPickerComponent,
    TimePickerComponent,
    TrainNumberPickerComponent,
    FiltersComponent,
    TrainSeriesButtonComponent,
    ResetFilterComponent,
  ],
  imports: [
    CommonModule,
    DatePickerModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  exports: [
    DirectionFilterComponent,
    FilterButtonComponent,
    FilterHeaderComponent,
    LineNumberFilterComponent,
    TimeFilterComponent,
    TrainNumberFilterComponent,
    LineNumberPickerComponent,
    TimePickerComponent,
    TrainNumberPickerComponent,
    FiltersComponent,
    TrainSeriesButtonComponent,
    ResetFilterComponent,
    DatePickerModule,
    TooltipModule,
  ],
})
export class FiltersModule {}
