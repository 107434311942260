// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.category-filter-container {
  text-indent: 0px;
  color: #9dbcda;
  font-size: 14px;
  width: 210px;
}
.category-filter-container ::ng-deep .p-focus {
  box-shadow: none;
}
.category-filter-container ::ng-deep .p-component {
  background: #3b5873;
  border: none;
  font-size: 14px;
}
.category-filter-container ::ng-deep .p-multiselect {
  width: 100%;
  height: 32px;
  line-height: 16px;
  border-radius: 5px;
}
.category-filter-container ::ng-deep .p-multiselect .p-multiselect-items-wrapper {
  border-radius: 5px 5px 0 0;
}
.category-filter-container ::ng-deep .p-multiselect-open {
  width: 100%;
  border-radius: 0 0 5px 5px;
  background: #24384a;
}
.category-filter-container ::ng-deep .p-multiselect-open .p-component {
  background: #24384a;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
