// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
/*#region Text*/
/*#endregion */
/*#region Elements*/
/**#region Event Container */
/*#endregion*/
/*#endregion */
/* #region Screen */
/* #endregion*/
.traffic-actions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
.traffic-actions-header h3 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}
.traffic-actions-header button {
  background-color: transparent;
  color: #52aaff;
  font-size: 12px;
}
.traffic-actions-header button:disabled {
  color: #3f5061;
}

ul {
  margin: 0 0 6px 0;
}
ul::-webkit-scrollbar {
  width: 10px;
}
ul::-webkit-scrollbar-track {
  background: #24384a;
}
ul::-webkit-scrollbar-thumb {
  background: #15202b;
  border-radius: 10px;
}
ul li {
  margin-bottom: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
