import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { UserRoles } from "../models/enums";
import {
  EventMessage,
  EventType,
  AuthenticationResult,
  InteractionStatus,
  RedirectRequest,
  AuthError,
  SilentRequest
} from "@azure/msal-browser";
import { filter, map, takeUntil } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { userReadWriteGroups } from "../../assets/user-roles";

/** Service to handle authorization and authentication */
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isLoggedIn = false;
  public loggedInStatusChanged = new Subject<boolean>();
  private accessToken: string;
  public accessTokenChanged = new Subject<string>();
  private readonly _destroying$ = new Subject<void>();
  private userRoles: UserRoles[];

  baseGraphUrl = "https://graph.microsoft.com/v1.0/";
  graphUser: any;
  graphUserGroups: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private httpClient: HttpClient,
  ) {
    this.setupLoginListeners();
  }

  setupLoginListeners() {
    this.msalService.handleRedirectObservable().pipe(
        takeUntil(this._destroying$)
    ).subscribe(
        (resp) => {
          if (this.getActiveAccount() === null) {
            this.msalService.loginRedirect();
          } else {
            if (resp?.account) {
              this.msalService.instance.setActiveAccount(resp.account);
            }
            console.log(`Active user: ${this.msalService.instance.getActiveAccount()?.name}`);
            this.isLoggedIn = true;
            this.getGraphUser();
          }
        }
    );

    this.msalBroadcastService.msalSubject$.pipe(
        filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
    ).subscribe(eventMsg => {
        console.log('login failure ' + JSON.stringify(eventMsg), eventMsg);
        this.isLoggedIn = false;
    });

    this.msalBroadcastService.msalSubject$.pipe(
        filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
    ).subscribe(eventMsg => {
        const authResult = eventMsg.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(authResult.account);
        // Check if user has correct tenant and client, else not logged in
        const user = this.getActiveAccount();
        if (user) {
          const tokenTenant = user.idTokenClaims['tid'];
          const tokenClient = user.idTokenClaims['aud'];
          if (!tokenTenant ||
              !tokenClient ||
              tokenTenant !== environment.config.tenantID ||
              tokenClient !== environment.config.clientID) {
            // Incorrect client/tenant, NOT logged in
            this.isLoggedIn = false;
          }
        } else {
          // Correct client/tenant, logged in
          this.isLoggedIn = true;
        }
    });

    this.msalBroadcastService.msalSubject$.pipe(
        filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
    ).subscribe((eventMsg) => {
        this.isLoggedIn = true;
    });

    this.msalBroadcastService.msalSubject$.pipe(
        filter((eventMsg: EventMessage) => eventMsg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
    ).subscribe(eventMsg => {
        if (this.getActiveAccount() == null) {
          this.msalService.loginRedirect();
          this.isLoggedIn = false;
        }
    });
  }


  //#region Public
  public getIsLoggedIn() {
    return this.isLoggedIn;
  }

  public subscribeTokenListeners(): void {
    // To sync when adding/removing accounts across tabs see
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md#syncing-logged-in-state-across-tabs-and-windows

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result?.payload) {
          const payload: AuthenticationResult = result.payload as AuthenticationResult
          this.msalService.instance.setActiveAccount(payload.account);
          this.updateAccessToken(payload.accessToken);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.updateLoggedInStatus(this.anyAccounts());
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Do something with the error
        }
        this.updateLoggedInStatus(this.anyAccounts());
        this.router.navigateByUrl("/not-authorized");
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
          msg.eventType === EventType.SSO_SILENT_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.updateLoggedInStatus(this.anyAccounts());

        // User needs to login manually via redirect
        if (
          result.error instanceof AuthError && result.error.errorCode &&
          (result.error.errorCode === 'consent_required' ||
            result.error.errorCode === 'interaction_required' ||
            result.error.errorCode === 'login_required' ||
            result.error.errorCode === 'token_renewal_error')
        ) {
          this.msalService.acquireTokenRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
        } else {
          this.router.navigateByUrl("/not-authorized");
        }
      });

    this.updateLoggedInStatus(this.anyAccounts());
  }

  public unsubscribeTokenListeners(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  public getApiAccessToken(scope: string[]): Observable<string> {
    if (!this.msalService.instance.getActiveAccount()) {
      this.checkAndSetActiveAccount();
    }
    const observable = this.msalService.acquireTokenSilent({scopes: [...scope]} as SilentRequest);
    return observable.pipe(
        map((result: AuthenticationResult) => result.accessToken)
    );
  }

  public getHighestLevelUserRole(): UserRoles {
    if (!Array.isArray(this.userRoles) || this.userRoles.length < 1) {
      return UserRoles.None;
    }

    if (this.userRoles.includes(UserRoles.Write)) return UserRoles.Write;
    if (this.userRoles.includes(UserRoles.Read)) return UserRoles.Read;

    return UserRoles.None;
  }

  public userHasMinimumRole(minRole: UserRoles): boolean {
    // All users meet this requirement because this requirement has no required roles
    if (minRole === UserRoles.None) return true;

    // If user does not have any roles
    if (!Array.isArray(this.userRoles) || this.userRoles.length < 1) {
      return false;
    }

    // If user has role
    if (this.userRoles.includes(minRole)) return true;

    return false;
  }

  public login(): void {
    localStorage.clear();
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  public logout(): void {
    this.msalService.logout();
  }

  public getUserName(): string {
    return this.msalService.instance.getActiveAccount()?.name;
  }
  //#endregion

  //#region Private
  private updateLoggedInStatus(newStatus: boolean): void {
    if (newStatus) {
      // Check if user has correct tenant and client, else not logged in
      const user = this.msalService.instance.getActiveAccount();
      const tokenTenant = user.idTokenClaims["tid"];
      const tokenClient = user.idTokenClaims["aud"];

      if (
        !tokenTenant ||
        !tokenClient ||
        tokenTenant !== environment.config.tenantID ||
        tokenClient !== environment.config.clientID
      ) {
        newStatus = false;
      }
    }

    if (this.isLoggedIn !== newStatus) {
      this.isLoggedIn = newStatus;
      this.loggedInStatusChanged.next(this.isLoggedIn);
    }
    this.updateUserRoles();
  }

  private updateAccessToken(newToken: string): void {
    if (this.accessToken !== newToken) {
      this.accessToken = newToken;
      this.accessTokenChanged.next(this.accessToken);
    }
  }

  private updateUserRoles(): void {
    if (!this.isLoggedIn) {
      this.userRoles = null;
      return;
    }
    this.getGraphUser();
  }

  private getGraphUser() {

    this.httpClient.get(this.baseGraphUrl + "me").subscribe({
      next: (data) => {
        this.graphUser = data;
        this.fetchGraphUserGroups();
      },
      error: (error) => {
        console.error(
          " Http get request to MS Graph failed" + JSON.stringify(error)
        );
      }
    });
  }

  private handleError(error: any) {
    console.error(error);
  }

  private hasWriteGroups() {
    const groups = userReadWriteGroups.DLI;
    if (this.graphUserGroups && this.graphUserGroups.value) {
      return this.graphUserGroups.value.findIndex(x => {
          return groups.includes(x.id);
        }) >=
        0;
    } else {
      return false;
    }
  }

  private fetchGraphUserGroups() {
    this.httpClient
      .get(`${this.baseGraphUrl}/users/${this.graphUser.id}/memberOf`)
      .subscribe({
        next: this.fetchGraphUserGroupsHandler.bind(this),
        error: this.handleError.bind(this)
      });
  }

  private fetchGraphUserGroupsHandler(response: any) {
    this.graphUserGroups = response;

    const rolesEnumArr = [];
    if (this.hasWriteGroups()) {
      rolesEnumArr.push(UserRoles.Read, UserRoles.Write);
    } else {
      rolesEnumArr.push(UserRoles.Read);
    }

    this.userRoles = rolesEnumArr;
    console.log('userRoles', this.userRoles);
    console.log('Is DLI', this.hasWriteGroups());
  }

  getActiveAccount() {
    return this.msalService.instance.getActiveAccount();
  }

  private anyAccounts(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }
}
