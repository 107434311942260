// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*#region Blue*/
/*#endregion */
/*#region Blue-Grey*/
/*#endregion */
/*#region Red*/
/*#endregion */
/*#region Green*/
/*#endregion */
/*#region Yellow*/
/*#endregion*/
/*#region Misc*/
/*#endregion*/
.checkbox-btn {
  width: 24px;
  height: 24px;
  border: 2px solid #9dbcda;
  border-radius: 5px;
  padding: 5px;
  transition: all 300ms ease-in-out;
  box-sizing: border-box;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}
.checkbox-wrapper:hover {
  cursor: pointer;
}

.checkbox-btn > .inner-fill {
  width: 20px; /*Checkbox size - 2x2 px for the border */
  height: 20px;
  background: transparent;
  border-radius: 3px;
  transition: all 300ms ease-in-out;
  margin-top: -5px;
  margin-left: -5px;
  position: absolute;
}

.checkbox-btn.active > .inner-fill {
  background: #3e84c9;
}

.checkbox-btn.active {
  border: 2px solid #3e84c9;
}

.checkbox-btn:hover {
  border-color: #74909f;
}

.checkbox-btn.disabled {
  border-color: #607387;
}

.checkbox-btn.disabled.active > .inner-fill {
  background: transparent;
}

.checkbox-btn > .inner-fill > svg {
  visibility: hidden;
  top: 3px;
  left: 3px;
  position: absolute;
  transition: all 200ms ease-in-out;
}

.checkbox-btn > .inner-fill > svg > path {
  stroke: #dce8f3;
}

.checkbox-btn.active > .inner-fill > svg {
  visibility: visible;
}

.checkbox-btn.disabled > .inner-fill > svg > path {
  stroke: #607387;
}

.checkbox-label {
  max-width: 300px;
  margin-left: 8px;
}

.checkbox-label-img {
  margin-right: 2px;
  height: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
